import { Col, Button, Form } from "react-bootstrap"
import { Formik } from 'formik';
import React, { useState } from "react"
import * as yup from 'yup';

const Message = (props) => (
  <Col className={`bottom-content ml-3 ${props.color}`}>{props.children}</Col>
)

export default (props) => {
  const [errorHappened, setErrorHappened] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const isDisabled = (errors, values) =>  disabled ||
                                          Object.keys(errors).length > 0 ||
                                          Object.values(values).filter(e =>  e).length === 0;

  function onFormChange(touched, errors, values) {
    if (disabled) {
      setDisabled(false);
      setSubmitted(false);
      setErrorHappened(false);
    }
  }

  function sendEmail(values, resetForm, setSubmitting){
    fetch(`https://44t5jl3r87.execute-api.us-west-2.amazonaws.com/production/${props.endPoint}`, {
      method: "POST",
      body: JSON.stringify(values)
    }).then(response => {
      resetForm();

      setDisabled(true);
      setErrorHappened(false);
      setSubmitted(true);
    })
    .catch(error => {
      console.log(error);
      setErrorHappened(true);
      setDisabled(false);
      setSubmitted(false);
      setSubmitting(false);
    })
  }

  return(
    <Formik
      validateOnChange
      validateOnBlur
      validationSchema={yup.object(props.schema)}
      onSubmit={(values, { resetForm, setSubmitting }) => sendEmail(values, resetForm, setSubmitting)}
      initialValues={{}}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isInvalid,
        isSubmitting,
        touched,
        values
      }) => (
        <Form noValidate onSubmit={handleSubmit} onChange={() => onFormChange(touched, errors, values)}>
          {/*<div className="pb-1 font-italic text-small"><span className="text-danger">*</span> INDICATES REQUIRED FIELD</div>*/}
          {props.children}
          <Form.Row>
            <Button className={isSubmitting ? "px-2" : ""} type="submit" disabled={isDisabled(errors, values)}>{isSubmitting ? "Sending..." : "Send"}</Button>
            {submitted && <Message color={'text-success'}>Thanks for submitting - we'll be in touch.</Message>}
            {errorHappened && <Message color={'text-danger'}>There was an error - please try again later.</Message>}
          </Form.Row>
        </Form>
      )}
    </Formik>
  )
}
