import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Col, Row } from "react-bootstrap";
import BackgroundBlock from "../components/background-block";
import ContactForm from "../components/contact-form";
import Content from "../components/content";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Content className="contact py-4" maxWidth="630px" mdxType="Content">
      <Row mdxType="Row">
        <Col className="center-content justify-content-start text-left" mdxType="Col">
          <h1>{`CONTACT ME`}</h1>
          <br />
          <p>{`Please fill out the form to request a meeting.`}</p>
          <ContactForm mdxType="ContactForm" />
          <div className="contact-info">
            <p>{`Musical Gumbo, LLC `}<br />{`
Mary Monsted `}<br />{`
Phone: 504-834-8255 `}<br />{`
Email: `}<a parentName="p" {...{
                "href": "mailto:Mary@MaryRMonsted.com"
              }}>{`Mary@MaryRMonsted.com`}</a></p>
          </div>
        </Col>
      </Row>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      