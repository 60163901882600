import { Col, Form } from "react-bootstrap"
import MaskedInput from "react-maskedinput";
import React from "react"
import { useFormikContext } from 'formik';

export default (props) => {
  const { errors, handleChange, handleBlur, touched, values, validateField } = useFormikContext();
  const customHandleChange = (e) => {
      e.preventDefault();
      handleChange(e);
      if(props.last) { validateField(props.name) }
  }

  let labelClass = props.label ? "" : "hide-mobile ";
  labelClass += props.hideLabel ? "hide" : "";

  let additionalProps = {};
  if (props.mask) {
    additionalProps["as"] = MaskedInput;

    switch(props.mask) {
      case "date":
        additionalProps["mask"] =  "11/11/1111";
        additionalProps["placeholder"] = "MM/DD/YYYY";
        break;
      case "height":
        additionalProps["mask"] =  "1' 11''";
        additionalProps["placeholder"] = "X' XX''";
        break;
      case "weight":
        additionalProps["mask"] =  "111 lbs";
        additionalProps["placeholder"] = "XXX lbs";
        break;
      case "phone":
        additionalProps["mask"] =  "(111) 111-1111";
        // additionalProps["placeholder"] = "(XXX) XXX-XXXX";
        break;
      case "ssn":
        additionalProps["mask"] =  "111-11-1111";
        additionalProps["placeholder"] = "XXX-XX-XXXX";
        break;
      default:
        break;
    }
  }

  const formControl = <Form.Control
    as={props.asControl}
    mask={props.mask}
    name={props.name}
    onBlur={handleBlur}
    onChange={(e) => { customHandleChange(e)}}
    isInvalid={!!touched[props.name] && !!errors[props.name]}
    placeholder={props.placeHolder || ""}
    type={props.type || "text"}
    value={values[props.name] || ""}

    {...additionalProps}
  />

  return(
    <Form.Group as={Col} md={props.md} controlId={`validationFormik${props.name}`}>
      <Form.Label className={labelClass}>
        {props.label || <div>&nbsp;</div>}
        {props.label && props.req && <span className="text-danger">*</span>}
      </Form.Label>
      {formControl}
      <Form.Control.Feedback type="invalid">
        {errors[props.name]}
      </Form.Control.Feedback>
    </Form.Group>
  )
}
