import { Form } from "react-bootstrap"
import CustomForm from "./custom-form"
import FormInput from "./form-input"
import React from "react"
import { constructSchema } from "../utils/form"

const schemaSpecs = {
  firstLastName: { type: "string", required: true },
  company: { type: "string", required: false },
  email: { type: "email", required: true },
  phone: { type: "phone", required: true },
  message: { type: "string", required: false }
};

export default (props) => {
  const schema = constructSchema(schemaSpecs);

  return(
    <CustomForm schema={schema} endPoint={"contactFormSubmissionMaryMonsted"}>
      <ContactFields />
    </CustomForm>
  )
}

const ContactFields = () => {
  const FormField = (props) => <FormInput {...props} req={schemaSpecs[props.name].required} />

  return (
    <>
      <Form.Row>
        <FormField hideLabel label={""} md={"12"} name={"firstLastName"} placeHolder={"First and Last Name (required)"} />
      </Form.Row>
      <Form.Row>
        <FormField hideLabel label={""} md={"12"} name={"company"} placeHolder={"Company Name"} />
      </Form.Row>
      <Form.Row>
        <FormField hideLabel label={""} md={"12"} name={"email"} placeHolder={"Your Email (required)"} type={"email"} />
      </Form.Row>
      <Form.Row>
        <FormField hideLabel label={""} mask={"phone"} md={"12"} name={"phone"} placeHolder={"Your Phone Number (required)"} type={"text"} />
      </Form.Row>
      <Form.Row>
        <FormField hideLabel last asControl={"textarea"} label={""} md={"12"} name={"message"} placeHolder={"Your Message"} />
      </Form.Row>
    </>
  )
}
